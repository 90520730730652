body {
  margin: 0;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

@font-face {
  font-family: 'ProximaN';
  src: url('https://hh-media-test.fra1.digitaloceanspaces.com/widget/ProximaNova-Light.woff2') format('woff2'),
    url('https://hh-media-test.fra1.digitaloceanspaces.com/widget/ProximaNova-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Domaine Display';
  src: url('https://hh-media-test.fra1.digitaloceanspaces.com/widget/DomaineDisp-Medium.woff2') format('woff2'),
    url('https://hh-media-test.fra1.digitaloceanspaces.com/widget/DomaineDisp-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}